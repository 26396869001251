/* eslint-disable no-nested-ternary */

// I need matchPath here for toSwitchedProject
// eslint-disable-next-line no-restricted-imports
import { matchPath, URLSearchParamsInit } from 'react-router';
import { enableExperimentDraftCreation } from '@gonfalon/dogfood-flags';
// eslint-disable-next-line no-restricted-imports
import { AppRoute, appRoute, unauthenticatedRoute } from '@gonfalon/router';
import nullthrows from 'nullthrows';

import { withChangeHistory } from './internal/withChangeHistory';
import { withNewProjectContext } from './internal/withNewProjectContext';
import { withProjectContext } from './internal/withProjectContext';
import { buildPath, withHash, withSearchParams } from './internal';

export * from './matchers';
export { toHref } from './toHref';

type URLOptions = {
  search?: URLSearchParamsInit;
  hash?: string;
};

type WithChangeHistory<T extends object> = T & { changeHistory?: true | string };

export { matchAppRoute } from './internal/matchAppRoute';

export function toRoot({ search, hash }: URLOptions = {}) {
  return buildPath(appRoute('/'), withSearchParams(search), withHash(hash));
}

export function toProjectHome({ projectKey }: { projectKey: string }, { search, hash }: URLOptions = {}) {
  return buildPath(appRoute('/projects/:projectKey', { projectKey }), withSearchParams(search), withHash(hash));
}

export function toFlags({ projectKey }: { projectKey: string }, { search, hash }: URLOptions = {}) {
  return buildPath(
    appRoute('/projects/:projectKey/flags', { projectKey }),
    withSearchParams(search),
    withHash(hash),
    withProjectContext(),
  );
}

export function toCreateSegment(
  { projectKey, environmentKey }: { projectKey: string; environmentKey: string },
  { search, hash }: URLOptions = {},
) {
  return buildPath(
    appRoute('/projects/:projectKey/segments/new', { projectKey }),
    withSearchParams(search),
    withHash(hash),
    withProjectContext({ selectedEnvironmentKey: environmentKey }),
  );
}

export function toSegments(
  { projectKey, environmentKey }: { projectKey: string; environmentKey: string },
  { search, hash }: URLOptions = {},
) {
  return buildPath(
    appRoute('/projects/:projectKey/segments', { projectKey }),
    withSearchParams(search),
    withHash(hash),
    withProjectContext({ selectedEnvironmentKey: environmentKey }),
  );
}

/**
 * @deprecated
 */
export function toLegacyExperiments(
  { projectKey, environmentKey }: { projectKey: string; environmentKey: string },
  { search, hash }: URLOptions = {},
) {
  return buildPath(
    appRoute(
      // @ts-expect-error TODO: remove this old-IA navigator
      '/:projKey/:envKey/experiments/legacy',
      { projKey: projectKey, envKey: environmentKey },
    ),
    withSearchParams(search),
    withHash(hash),
  );
}

export function toMetricAnalytics(
  { projectKey, environmentKey }: { projectKey: string; environmentKey: string },
  { search, hash }: URLOptions = {},
) {
  return buildPath(
    appRoute('/projects/:projectKey/metrics/analytics', { projectKey }),
    withSearchParams(search),
    withHash(hash),
    withProjectContext({ selectedEnvironmentKey: environmentKey }),
  );
}

export function toAnalyticsQueryResults(
  {
    projectKey,
    environmentKey,
    analyticsQueryResultId,
  }: { projectKey: string; environmentKey: string; analyticsQueryResultId: string },
  { search, hash }: URLOptions = {},
) {
  return buildPath(
    appRoute('/projects/:projectKey/metrics/analytics/new/:analyticsQueryResultId', {
      projectKey,
      analyticsQueryResultId,
    }),
    withSearchParams(search),
    withHash(hash),
    withProjectContext({ selectedEnvironmentKey: environmentKey }),
  );
}

export function toMetricImpact(
  { projectKey, metricKey }: { projectKey: string; metricKey: string },
  { hash }: URLOptions = {},
) {
  return buildPath(
    appRoute('/projects/:projectKey/metrics/:metricKey/impact', { projectKey, metricKey }),
    withHash(hash),
  );
}

export function toMetricGroup(
  {
    projectKey,
    metricGroupKey,
  }: {
    projectKey: string;
    metricGroupKey: string;
  },
  { search, hash }: URLOptions = {},
) {
  return buildPath(
    appRoute('/projects/:projectKey/metrics/groups/:metricGroupKey/details', { projectKey, metricGroupKey }),
    withSearchParams(search),
    withHash(hash),
  );
}

export function toMetricDetails(
  { projectKey, metricKey }: { projectKey: string; metricKey: string },
  { search, hash }: URLOptions = {},
) {
  return buildPath(
    appRoute('/projects/:projectKey/metrics/:metricKey/details', { projectKey, metricKey }),
    withSearchParams(search),
    withHash(hash),
  );
}

export function toMetric(
  { projectKey, metricKey }: { projectKey: string; metricKey: string },
  { search, hash }: URLOptions = {},
) {
  return buildPath(
    appRoute('/projects/:projectKey/metrics/:metricKey/details', {
      projectKey,
      metricKey,
    }),
    withSearchParams(search),
    withHash(hash),
  );
}

export function toMetrics({ projectKey }: { projectKey: string }, { search, hash }: URLOptions = {}) {
  return buildPath(appRoute('/projects/:projectKey/metrics', { projectKey }), withSearchParams(search), withHash(hash));
}

export function toObserve(
  { projectKey, environmentKey }: { projectKey: string; environmentKey: string },
  { search, hash }: URLOptions = {},
) {
  return buildPath(
    appRoute('/projects/:projectKey/observe', { projectKey }),
    withSearchParams(search),
    withHash(hash),
    withProjectContext({ selectedEnvironmentKey: environmentKey }),
  );
}

export function getProductAnalyticsAppRoute({ projectKey }: { projectKey: string }) {
  return appRoute('/projects/:projectKey/product-analytics', { projectKey });
}

export function toProductAnalyticsRoute(
  relativePath: string,
  { projectKey, environmentKey, ...params }: { projectKey: string; environmentKey: string } & Record<string, string>,
) {
  let path = getProductAnalyticsAppRoute({ projectKey });
  path += relativePath;
  return buildPath(
    path,
    withSearchParams(params || {}),
    withProjectContext({ selectedEnvironmentKey: environmentKey }),
  );
}

export function toProductAnalytics({ projectKey, environmentKey }: { projectKey: string; environmentKey: string }) {
  return buildPath(
    getProductAnalyticsAppRoute({ projectKey }),
    withProjectContext({ selectedEnvironmentKey: environmentKey }),
  );
}
export function toSnowflakeProductAnalyticsIntegration() {
  return '/product-analytics/integrations/snowflake';
}

export function toObserveNewMetric(
  { projectKey, environmentKey }: { projectKey: string; environmentKey: string },
  { search, hash }: URLOptions = {},
) {
  return buildPath(
    appRoute('/projects/:projectKey/observe/new-metric', { projectKey }),
    withSearchParams(search),
    withHash(hash),
    withProjectContext({ selectedEnvironmentKey: environmentKey }),
  );
}

export function toObserveExpand(
  {
    projectKey,
    environmentKey,
    eventKey,
    eventKind,
  }: { projectKey: string; environmentKey: string; eventKey: string; eventKind: string },
  { search, hash }: URLOptions = {},
) {
  return buildPath(
    appRoute('/projects/:projectKey/observe/expand/:eventKind/:eventKey', {
      projectKey,
      eventKey: encodeURIComponent(eventKey),
      eventKind,
    }),
    withSearchParams(search),
    withHash(hash),
    withProjectContext({ selectedEnvironmentKey: environmentKey }),
  );
}

export function toExperiments(
  { projectKey, environmentKey }: { projectKey: string; environmentKey: string },
  { search, hash }: URLOptions = {},
) {
  return buildPath(
    appRoute('/projects/:projectKey/experiments', { projectKey }),
    withSearchParams(search),
    withHash(hash),
    withProjectContext({ selectedEnvironmentKey: environmentKey }),
  );
}

export function toHoldoutsRoot({ projectKey }: { projectKey: string }, { search, hash }: URLOptions = {}) {
  return buildPath(
    appRoute('/projects/:projectKey/holdouts', { projectKey }),
    withSearchParams(search),
    withHash(hash),
  );
}

export function toHoldoutsCreate({ projectKey }: { projectKey: string }, { search, hash }: URLOptions = {}) {
  return buildPath(
    appRoute('/projects/:projectKey/holdouts/create', { projectKey }),
    withSearchParams(search),
    withHash(hash),
  );
}

export function toHoldoutDetails(
  { projectKey, holdoutKey, environmentKey }: { projectKey: string; holdoutKey: string; environmentKey: string },
  { search, hash }: URLOptions = {},
) {
  return buildPath(
    appRoute('/projects/:projectKey/holdouts/:holdoutKey/details', { projectKey, holdoutKey }),
    withSearchParams(search),
    withHash(hash),
    withProjectContext({ selectedEnvironmentKey: environmentKey }),
  );
}

export function toLiveEvents(
  { projectKey, environmentKey }: { projectKey: string; environmentKey: string },
  { search, hash }: URLOptions = {},
) {
  return buildPath(
    appRoute('/projects/:projectKey/live', { projectKey }),
    withSearchParams(search),
    withHash(hash),
    withProjectContext({ selectedEnvironmentKey: environmentKey }),
  );
}

export function toLiveFlagEvents(
  { projectKey, flagKey, environmentKey }: { projectKey: string; flagKey: string; environmentKey: string },
  { search, hash }: URLOptions = {},
) {
  return buildPath(
    appRoute('/projects/:projectKey/live/flags/:flagKey', { projectKey, flagKey }),
    withSearchParams(search),
    withHash(hash),
    withProjectContext({ selectedEnvironmentKey: environmentKey }),
  );
}

export function toSessions(
  { projectKey, environmentKey }: { projectKey: string; environmentKey: string },
  { search, hash }: URLOptions = {},
) {
  return buildPath(
    appRoute('/projects/:projectKey/sessions', { projectKey }),
    withSearchParams(search),
    withHash(hash),
    withProjectContext({ selectedEnvironmentKey: environmentKey }),
  );
}

export function toSessionDetails(
  { projectKey, environmentKey, sessionId }: { projectKey: string; environmentKey: string; sessionId: string },
  { search, hash }: URLOptions = {},
) {
  return buildPath(
    appRoute('/projects/:projectKey/sessions/:sessionId/*', { projectKey, sessionId }),
    withSearchParams(search),
    withHash(hash),
    withProjectContext({ selectedEnvironmentKey: environmentKey }),
  );
}

export function toErrorMonitoring(
  { projectKey, environmentKey }: { projectKey: string; environmentKey: string },
  { search, hash }: URLOptions = {},
) {
  return buildPath(
    appRoute('/projects/:projectKey/errors', { projectKey }),
    withSearchParams(search),
    withHash(hash),
    withProjectContext({ selectedEnvironmentKey: environmentKey }),
  );
}

export function toErrorMonitoringIssue(
  { projectKey, environmentKey, issueId }: { projectKey: string; environmentKey: string; issueId: string },
  { search, hash }: URLOptions = {},
) {
  return buildPath(
    appRoute('/projects/:projectKey/errors/:issueId', { projectKey, issueId }),
    withSearchParams(search),
    withHash(hash),
    withProjectContext({ selectedEnvironmentKey: environmentKey }),
  );
}

export function toErrorMonitoringIssueJSON(
  { projectKey, environmentKey, issueId }: { projectKey: string; environmentKey: string; issueId: string },
  { search, hash }: URLOptions = {},
) {
  return buildPath(
    appRoute('/projects/:projectKey/errors/:issueId/json', { projectKey, issueId }),
    withSearchParams(search),
    withHash(hash),
    withProjectContext({ selectedEnvironmentKey: environmentKey }),
  );
}

/**
 * @deprecated
 */
export function toAuditLog(
  { projectKey, environmentKey }: { projectKey: string; environmentKey: string },
  { search, hash }: URLOptions = {},
) {
  return buildPath(
    appRoute(
      // @ts-expect-error TODO: remove this old-IA navigator
      '/:projKey/:envKey/audit/*',
      { projKey: projectKey, envKey: environmentKey },
    ),
    withSearchParams(search),
    withHash(hash),
  );
}

export function toFlag(
  { projectKey, flagKey, environmentKey }: { projectKey: string; flagKey: string; environmentKey: string },
  { search, hash }: URLOptions = {},
) {
  return buildPath(
    appRoute('/projects/:projectKey/flags/:flagKey', {
      projectKey,
      flagKey,
    }),
    withSearchParams(search),
    withHash(hash),
    withProjectContext({ selectedEnvironmentKey: environmentKey }),
  );
}

export function toFlagTargeting(
  { projectKey, flagKey, environmentKey }: { projectKey: string; flagKey: string; environmentKey: string },
  { search, hash, changeHistory }: WithChangeHistory<URLOptions> = {},
) {
  return buildPath(
    appRoute('/projects/:projectKey/flags/:flagKey/targeting', {
      projectKey,
      flagKey,
    }),
    withSearchParams(search),
    withHash(hash),
    withProjectContext({ selectedEnvironmentKey: environmentKey }),
    withChangeHistory(changeHistory),
  );
}

export function toSegmentTargeting(
  { projectKey, segmentKey, environmentKey }: { projectKey: string; segmentKey: string; environmentKey: string },
  { search, hash, changeHistory }: WithChangeHistory<URLOptions> = {},
) {
  return buildPath(
    appRoute('/projects/:projectKey/segments/:segmentKey/targeting', {
      projectKey,
      segmentKey,
    }),
    withSearchParams(search),
    withHash(hash),
    withProjectContext({ selectedEnvironmentKey: environmentKey }),
    withChangeHistory(changeHistory),
  );
}

export function toSegmentFlags(
  { projectKey, segmentKey, environmentKey }: { projectKey: string; segmentKey: string; environmentKey: string },
  { search, hash }: URLOptions = {},
) {
  return buildPath(
    appRoute('/projects/:projectKey/segments/:segmentKey/overview', {
      projectKey,
      segmentKey,
    }),
    withSearchParams(search),
    withHash(hash),
    withProjectContext({ selectedEnvironmentKey: environmentKey }),
  );
}

export function toSegmentSettings(
  { projectKey, segmentKey, environmentKey }: { projectKey: string; segmentKey: string; environmentKey: string },
  { search, hash }: URLOptions = {},
) {
  return buildPath(
    appRoute('/projects/:projectKey/segments/:segmentKey/settings', {
      projectKey,
      segmentKey,
    }),
    withSearchParams(search),
    withHash(hash),
    withProjectContext({ selectedEnvironmentKey: environmentKey }),
  );
}

export function toSegmentHistory(
  { projectKey, segmentKey, environmentKey }: { projectKey: string; segmentKey: string; environmentKey: string },
  { search, hash }: URLOptions = {},
) {
  return buildPath(
    appRoute('/projects/:projectKey/segments/:segmentKey/history/*', {
      projectKey,
      segmentKey,
    }),
    withSearchParams(search),
    withHash(hash),
    withProjectContext({ selectedEnvironmentKey: environmentKey }),
  );
}

export function toFlagVariations(
  { projectKey, flagKey }: { projectKey: string; flagKey: string },
  { search, hash }: URLOptions = {},
) {
  return buildPath(
    appRoute('/projects/:projectKey/flags/:flagKey/variations', {
      projectKey,
      flagKey,
    }),
    withSearchParams(search),
    withHash(hash),
    withProjectContext(),
  );
}

/**
 * @deprecated
 */
export function toFlagHistory(
  { projectKey, flagKey, environmentKey }: { projectKey: string; flagKey: string; environmentKey?: string },
  { search, hash }: URLOptions = {},
) {
  return buildPath(
    appRoute(
      // @ts-expect-error TODO: remove this old-IA navigator
      '/:projKey/:envKey/features/:flagKey/history/*',
      {
        projKey: projectKey,
        envKey: nullthrows(environmentKey),
        flagKey,
      },
    ),
    withSearchParams(search),
    withHash(hash),
  );
}

export function toFlagVariationPendingChanges(
  { projectKey, flagKey, environmentKey }: { projectKey: string; flagKey: string; environmentKey: string },
  { search, hash }: URLOptions = {},
) {
  return buildPath(
    appRoute('/projects/:projectKey/flags/:flagKey/variations/pending-changes', {
      projectKey,
      flagKey,
    }),
    withSearchParams(search),
    withHash(hash),
    withProjectContext({ selectedEnvironmentKey: environmentKey }),
  );
}

export function toFlagVariationConflict(
  {
    projectKey,
    flagKey,
    conflictIndex,
    environmentKey,
  }: { projectKey: string; flagKey: string; conflictIndex: string; environmentKey: string },
  { search, hash }: URLOptions = {},
) {
  return buildPath(
    appRoute('/projects/:projectKey/flags/:flagKey/variations/conflicts/:conflictIndex', {
      projectKey,
      flagKey,
      conflictIndex,
    }),
    withSearchParams(search),
    withHash(hash),
    withProjectContext({ selectedEnvironmentKey: environmentKey }),
  );
}

export function toFlagTargetingConflict(
  {
    projectKey,
    flagKey,
    conflictIndex,
    environmentKey,
  }: { projectKey: string; flagKey: string; conflictIndex: string; environmentKey: string },
  { search, hash }: URLOptions = {},
) {
  return buildPath(
    appRoute('/projects/:projectKey/flags/:flagKey/targeting/conflicts/:conflictIndex', {
      projectKey,
      flagKey,
      conflictIndex,
    }),
    withSearchParams(search),
    withHash(hash),
    withProjectContext({ selectedEnvironmentKey: environmentKey }),
  );
}

export function toFlagEnvironmentSettings(
  { projectKey, flagKey }: { projectKey: string; flagKey: string },
  { search, hash }: URLOptions = {},
) {
  return buildPath(
    appRoute('/projects/:projectKey/flags/:flagKey/environments', {
      projectKey,
      flagKey,
    }),
    withSearchParams(search),
    withHash(hash),
    withProjectContext(),
  );
}

export function toFlagDefaults({ projectKey }: { projectKey: string }, { search, hash }: URLOptions = {}) {
  return buildPath(
    appRoute('/projects/:projectKey/settings/flags', {
      projectKey,
    }),
    withSearchParams(search),
    withHash(hash),
  );
}

export function toFlagSettings(
  { projectKey, flagKey, environmentKey }: { projectKey: string; flagKey: string; environmentKey: string },
  { search, hash }: URLOptions = {},
) {
  return buildPath(
    appRoute('/projects/:projectKey/flags/:flagKey/settings', {
      projectKey,
      flagKey,
    }),
    withSearchParams(search),
    withHash(hash),
    withProjectContext({ selectedEnvironmentKey: environmentKey }),
  );
}

export function toFlagPendingChange(
  {
    projectKey,
    flagKey,
    pendingId,
    environmentKey,
  }: { projectKey: string; flagKey: string; pendingId: string; environmentKey: string },
  { search, hash }: URLOptions = {},
) {
  return buildPath(
    appRoute('/projects/:projectKey/flags/:flagKey/targeting/pending-changes/:pendingId', {
      projectKey,
      flagKey,
      pendingId,
    }),
    withSearchParams(search),
    withHash(hash),
    withProjectContext({ selectedEnvironmentKey: environmentKey }),
  );
}

export function toFlagPendingTargetingChanges(
  { projectKey, flagKey, environmentKey }: { projectKey: string; flagKey: string; environmentKey: string },
  { search, hash }: URLOptions = {},
) {
  return buildPath(
    appRoute('/projects/:projectKey/flags/:flagKey/targeting/pending-changes', {
      projectKey,
      flagKey,
    }),
    withSearchParams(search),
    withHash(hash),
    withProjectContext({ selectedEnvironmentKey: environmentKey }),
  );
}

export function toApprovals({ projectKey }: { projectKey: string }, { search, hash }: URLOptions = {}) {
  return buildPath(
    appRoute('/projects/:projectKey/approvals', { projectKey }),
    withSearchParams(search),
    withHash(hash),
  );
}

export function toApproval(
  { approvalRequestId, projectKey }: { projectKey: string; approvalRequestId: string },
  { search, hash }: URLOptions = {},
) {
  return buildPath(
    appRoute('/projects/:projectKey/approvals/:approvalRequestId', {
      approvalRequestId,
      projectKey: nullthrows(projectKey),
    }),
    withSearchParams(search),
    withHash(hash),
  );
}

export function toFlagApproval(
  {
    projectKey,
    flagKey,
    approvalRequestId,
    environmentKey,
  }: { projectKey: string; flagKey: string; approvalRequestId: string; environmentKey: string },
  { search, hash }: URLOptions = {},
) {
  return buildPath(
    appRoute('/projects/:projectKey/flags/:flagKey/approvals/:approvalRequestId', {
      projectKey,
      flagKey,
      approvalRequestId,
    }),
    withSearchParams(search),
    withHash(hash),
    withProjectContext({ selectedEnvironmentKey: environmentKey }),
  );
}

export function toContexts(
  { projectKey, environmentKey }: { projectKey: string; environmentKey: string },
  { search, hash }: URLOptions = {},
) {
  return buildPath(
    appRoute('/projects/:projectKey/contexts', { projectKey }),
    withSearchParams(search),
    withHash(hash),
    withProjectContext({ selectedEnvironmentKey: environmentKey }),
  );
}

export function toContextKinds(
  { projectKey, environmentKey }: { projectKey: string; environmentKey: string },
  { search, hash }: URLOptions = {},
) {
  return buildPath(
    appRoute('/projects/:projectKey/settings/context-kinds', { projectKey }),
    withSearchParams(search),
    withHash(hash),
    withProjectContext({ selectedEnvironmentKey: environmentKey }),
  );
}

/**
 * @deprecated
 */
export function toConnectFlag(
  { projectKey, environmentKey }: { projectKey: string; environmentKey: string },
  { search, hash }: URLOptions = {},
) {
  return buildPath(
    appRoute(
      // @ts-expect-error TODO: remove this old-IA navigator
      '/:projKey/:envKey/features/connect',
      { projKey: projectKey, envKey: environmentKey },
    ),
    withSearchParams(search),
    withHash(hash),
  );
}

export function toCreateFlag(
  { projectKey, environmentKey }: { projectKey: string; environmentKey: string },
  { search, hash }: URLOptions = {},
) {
  return buildPath(
    appRoute('/projects/:projectKey/flags/new', {
      projectKey,
    }),
    withSearchParams(search),
    withHash(hash),
    withProjectContext({ selectedEnvironmentKey: environmentKey }),
  );
}

export function toArchiveFlagFromList(
  { projectKey, flagKey, environmentKey }: { projectKey: string; flagKey: string; environmentKey: string },
  { search, hash }: URLOptions = {},
) {
  return buildPath(
    appRoute(
      // @ts-expect-error TODO: remove this old-IA navigator
      '/:projKey/:envKey/features/:flagKey/archive',
      {
        projKey: projectKey,
        envKey: environmentKey,
        flagKey,
      },
    ),
    withSearchParams(search),
    withHash(hash),
  );
}

export function toDeprecateFlagFromList(
  { projectKey, flagKey }: { projectKey: string; flagKey: string },
  { search, hash }: URLOptions = {},
) {
  return buildPath(
    appRoute('/projects/:projectKey/flags/:flagKey/settings/deprecate', { projectKey, flagKey }),
    withSearchParams(search),
    withHash(hash),
  );
}

export function toAddToCode(
  { projectKey, flagKey }: { projectKey: string; flagKey: string },
  { search, hash }: URLOptions = {},
) {
  return buildPath(
    appRoute('/projects/:projectKey/flags/:flagKey/add-to-code', { projectKey, flagKey }),
    withSearchParams(search),
    withHash(hash),
  );
}

export function toArchiveFlagFromSettings(
  { projectKey, flagKey }: { projectKey: string; flagKey: string },
  { search, hash }: URLOptions = {},
) {
  return buildPath(
    appRoute('/projects/:projectKey/flags/:flagKey/settings/archive', { projectKey, flagKey }),
    withSearchParams(search),
    withHash(hash),
    withProjectContext(),
  );
}
export function toDeprecateFlagFromSettings(
  { projectKey, flagKey }: { projectKey: string; flagKey: string },
  { search, hash }: URLOptions = {},
) {
  return buildPath(
    appRoute('/projects/:projectKey/flags/:flagKey/settings/deprecate', { projectKey, flagKey }),
    withSearchParams(search),
    withHash(hash),
  );
}

export function toFlagOverview(
  { projectKey, flagKey, environmentKey }: { projectKey: string; flagKey: string; environmentKey: string },
  { search, hash }: URLOptions = {},
) {
  return buildPath(
    appRoute('/projects/:projectKey/flags/:flagKey/environment-overview', { projectKey, flagKey }),
    withSearchParams(search),
    withHash(hash),
    withProjectContext({ selectedEnvironmentKey: environmentKey }),
  );
}

export function toFlagExperiments({
  projectKey,
  flagKey,
  environmentKey,
}: {
  projectKey: string;
  flagKey: string;
  environmentKey: string;
}) {
  // flags no longer have an experiment tab, experiments are listed in the flag sidebar
  return toFlagTargeting({ projectKey, flagKey, environmentKey });
}

/**
 * @deprecated
 */
export function toFlagExperimentsSettings(
  { projectKey, flagKey, environmentKey }: { projectKey: string; flagKey: string; environmentKey: string },
  { search, hash }: URLOptions = {},
) {
  return buildPath(
    appRoute(
      // @ts-expect-error TODO: remove this old-IA navigator
      '/:projKey/:envKey/features/:flagKey/experiments/settings',
      {
        projKey: projectKey,
        envKey: environmentKey,
        flagKey,
      },
    ),
    withSearchParams(search),
    withHash(hash),
  );
}

export function toFlagComparison(
  {
    projectKey,
    environmentPair,
    environmentKey,
  }: { projectKey: string; environmentPair: string; environmentKey: string },
  { search, hash }: URLOptions = {},
) {
  return buildPath(
    appRoute('/projects/:projectKey/compare/:environmentPair', { projectKey, environmentPair }),
    withSearchParams(search),
    withHash(hash),
    withProjectContext({ selectedEnvironmentKey: environmentKey }),
  );
}

export function toFlagSync(
  {
    projectKey,
    flagKey,
    environmentPair,
    environmentKey,
  }: { projectKey: string; flagKey: string; environmentPair: string; environmentKey: string },
  { search, hash }: URLOptions = {},
) {
  return buildPath(
    appRoute('/projects/:projectKey/compare/:environmentPair/flags/:flagKey', {
      projectKey,
      flagKey,
      environmentPair,
    }),
    withSearchParams(search),
    withHash(hash),
    withProjectContext({ selectedEnvironmentKey: environmentKey }),
  );
}

export function toContextInstance(
  { projectKey, instanceId, environmentKey }: { projectKey: string; instanceId: string; environmentKey: string },
  { search, hash }: URLOptions = {},
) {
  return buildPath(
    appRoute('/projects/:projectKey/contexts/instances/:instanceId', { projectKey, instanceId }),
    withSearchParams(search),
    withHash(hash),
    withProjectContext({ selectedEnvironmentKey: environmentKey }),
  );
}

export function toContext(
  { projectKey, kind, key, environmentKey }: { projectKey: string; kind: string; key: string; environmentKey: string },
  { search, hash }: URLOptions = {},
) {
  const encodedKey = encodeURIComponent(key);
  return buildPath(
    appRoute('/projects/:projectKey/contexts/kinds/:kind/instances/:key', { projectKey, kind, key: encodedKey }),
    withSearchParams(search),
    withHash(hash),
    withProjectContext({ selectedEnvironmentKey: environmentKey }),
  );
}

export function toFlagWorkflows(
  { projectKey, flagKey, environmentKey }: { projectKey: string; flagKey: string; environmentKey: string },
  { search, hash }: URLOptions = {},
) {
  return buildPath(
    appRoute('/projects/:projectKey/flags/:flagKey/workflows', { projectKey, flagKey }),
    withSearchParams(search),
    withHash(hash),
    withProjectContext({ selectedEnvironmentKey: environmentKey }),
  );
}

export function toFlagMonitoring(
  { projectKey, flagKey, environmentKey }: { projectKey: string; flagKey: string; environmentKey: string },
  { search, hash }: URLOptions = {},
) {
  return buildPath(
    appRoute('/projects/:projectKey/flags/:flagKey/monitoring', {
      projectKey,
      flagKey,
    }),
    withSearchParams(search),
    withHash(hash),
    withProjectContext({ selectedEnvironmentKey: environmentKey }),
  );
}

export function toFlagCodeReferences(
  { projectKey, flagKey }: { projectKey: string; flagKey: string },
  { search, hash }: URLOptions = {},
) {
  return buildPath(
    appRoute('/projects/:projectKey/flags/:flagKey/code-refs', { projectKey, flagKey }),
    withSearchParams(search),
    withHash(hash),
    withProjectContext(),
  );
}

export function toFlagLinks(
  { projectKey, flagKey, environmentKey }: { projectKey: string; flagKey: string; environmentKey: string },
  { search, hash }: URLOptions = {},
) {
  return buildPath(
    appRoute('/projects/:projectKey/flags/:flagKey/links', { projectKey, flagKey }),
    withSearchParams(search),
    withHash(hash),
    withProjectContext({ selectedEnvironmentKey: environmentKey }),
  );
}

export function toFlagWorkflow(
  {
    projectKey,
    flagKey,
    workflowId,
    environmentKey,
  }: { projectKey: string; flagKey: string; workflowId: string; environmentKey: string },
  { search, hash }: URLOptions = {},
) {
  return buildPath(
    appRoute('/projects/:projectKey/flags/:flagKey/workflows/:workflowId', { projectKey, flagKey, workflowId }),
    withSearchParams(search),
    withHash(hash),
    withProjectContext({ selectedEnvironmentKey: environmentKey }),
  );
}

export function toCreateFlagWorkflow(
  { projectKey, flagKey, environmentKey }: { projectKey: string; flagKey: string; environmentKey: string },
  { search, hash }: URLOptions = {},
) {
  return buildPath(
    appRoute('/projects/:projectKey/flags/:flagKey/workflows/new', { projectKey, flagKey }),
    withSearchParams(search),
    withHash(hash),
    withProjectContext({ selectedEnvironmentKey: environmentKey }),
  );
}

export function toFlagExcludeRules(
  { projectKey, flagKey, environmentKey }: { projectKey: string; flagKey: string; environmentKey: string },
  { search, hash }: URLOptions = {},
) {
  return buildPath(
    appRoute('/projects/:projectKey/flags/:flagKey/targeting/exclude-rules', { projectKey, flagKey }),
    withSearchParams(search),
    withHash(hash),
    withProjectContext({ selectedEnvironmentKey: environmentKey }),
  );
}

export function toExperimentDesign(
  { projectKey, experimentKey, environmentKey }: { projectKey: string; experimentKey: string; environmentKey: string },
  { search, hash }: URLOptions = {},
) {
  return buildPath(
    appRoute('/projects/:projectKey/experiments/:experimentKey/design', { projectKey, experimentKey }),
    withSearchParams(search),
    withHash(hash),
    withProjectContext({ selectedEnvironmentKey: environmentKey }),
  );
}

export function toExperimentResults(
  { projectKey, experimentKey, environmentKey }: { projectKey: string; experimentKey: string; environmentKey: string },
  { search, hash }: URLOptions = {},
) {
  return buildPath(
    appRoute('/projects/:projectKey/experiments/:experimentKey/results', { projectKey, experimentKey }),
    withSearchParams(search),
    withHash(hash),
    withProjectContext({ selectedEnvironmentKey: environmentKey }),
  );
}

export function toExperimentIterations(
  { projectKey, experimentKey, environmentKey }: { projectKey: string; experimentKey: string; environmentKey: string },
  { search, hash }: URLOptions = {},
) {
  return buildPath(
    appRoute('/projects/:projectKey/experiments/:experimentKey/iterations', {
      projectKey,
      experimentKey,
    }),
    withSearchParams(search),
    withHash(hash),
    withProjectContext({ selectedEnvironmentKey: environmentKey }),
  );
}

export function toCreateExperiment(
  { projectKey, environmentKey }: { projectKey: string; environmentKey: string },
  { search, hash }: URLOptions = {},
) {
  if (enableExperimentDraftCreation()) {
    return buildPath(
      appRoute('/projects/:projectKey/experiments/new', { projectKey }),
      withSearchParams(search),
      withHash(hash),
      withProjectContext({ selectedEnvironmentKey: environmentKey }),
    );
  }

  return buildPath(
    appRoute('/projects/:projectKey/experiments/create', { projectKey }),
    withSearchParams(search),
    withHash(hash),
    withProjectContext({ selectedEnvironmentKey: environmentKey }),
  );
}

export function toCreateMetric(
  { projectKey, environmentKey }: { projectKey: string; environmentKey: string },
  { search, hash }: URLOptions = {},
) {
  return buildPath(
    appRoute('/projects/:projectKey/metrics/new', { projectKey }),
    withSearchParams(search),
    withHash(hash),
    withProjectContext({ selectedEnvironmentKey: environmentKey }),
  );
}

export function toExperimentIteration(
  { projectKey, experimentKey, iterationId }: { projectKey: string; experimentKey: string; iterationId: string },
  { search, hash }: URLOptions = {},
) {
  return buildPath(
    appRoute('/projects/:projectKey/experiments/:experimentKey/iterations/:iterationId', {
      projectKey,
      experimentKey,
      iterationId,
    }),
    withSearchParams(search),
    withHash(hash),
    withProjectContext(),
  );
}

export function toTeamHistory({ teamKey }: { teamKey: string }, { search, hash }: URLOptions = {}) {
  return buildPath(
    appRoute('/settings/teams/:teamKey/history/*', { teamKey }),
    withSearchParams(search),
    withHash(hash),
  );
}

export function toReleasePipelines({ projectKey }: { projectKey: string }, { search, hash }: URLOptions = {}) {
  return buildPath(
    appRoute('/projects/:projectKey/settings/release-pipelines', { projectKey }),
    withSearchParams(search),
    withHash(hash),
  );
}

export function toReleasePipeline(
  { projectKey, releasePipelineKey }: { projectKey: string; releasePipelineKey: string },
  { search, hash }: URLOptions = {},
) {
  return buildPath(
    appRoute('/projects/:projectKey/settings/release-pipelines/:releasePipelineKey', {
      projectKey,
      releasePipelineKey,
    }),
    withSearchParams(search),
    withHash(hash),
    withProjectContext(),
  );
}

export function toCreateReleasePipeline({ projectKey }: { projectKey: string }, { search, hash }: URLOptions = {}) {
  return buildPath(
    appRoute('/projects/:projectKey/settings/release-pipelines/new', { projectKey }),
    withSearchParams(search),
    withHash(hash),
    withProjectContext(),
  );
}

export function toReleases(
  { projectKey, releasePipelineKey }: { projectKey: string; releasePipelineKey?: string },
  { search, hash }: URLOptions = {},
) {
  if (!releasePipelineKey) {
    // this only happens if you try to navigate to the releases view when no release pipelines exist yet
    return toReleasePipelines({ projectKey }, { search, hash });
  }

  return buildPath(
    appRoute('/projects/:projectKey/releases/:releasePipelineKey', { projectKey, releasePipelineKey }),
    withSearchParams(search),
    withHash(hash),
    withProjectContext(),
  );
}

export function toAccountSettings({ search, hash }: URLOptions = {}) {
  return buildPath(appRoute('/settings/general'), withSearchParams(search), withHash(hash));
}

export function toIntegrations({ search, hash }: URLOptions = {}) {
  return buildPath(appRoute('/settings/integrations'), withSearchParams(search), withHash(hash));
}

export function toInstallSDK(
  { projectKey, environmentKey }: { projectKey: string; environmentKey: string },
  { search, hash }: URLOptions = {},
) {
  return buildPath(
    appRoute('/projects/:projectKey/install-sdk', { projectKey }),
    withSearchParams(search),
    withHash(hash),
    withProjectContext({ selectedEnvironmentKey: environmentKey }),
  );
}

export function toJoinOrganization({ token }: { token: string }, { search }: URLOptions = {}) {
  return buildPath(unauthenticatedRoute('/join-your-organization/:token', { token }), withSearchParams(search));
}

export function toChooseOrganization({ email }: { email: string }, { search, hash }: URLOptions = {}) {
  return buildPath(
    unauthenticatedRoute('/choose-organization/:email', { email }),
    withSearchParams(search),
    withHash(hash),
  );
}

export function toOnboarding({ projectKey }: { projectKey: string }, { search, hash }: URLOptions = {}) {
  return buildPath(
    appRoute('/projects/:projectKey/onboarding', { projectKey }),
    withSearchParams(search),
    withHash(hash),
    withProjectContext(),
  );
}

export function toOnboardingPath(
  { projectKey, onboardingPath }: { projectKey: string; onboardingPath: string },
  { search, hash }: URLOptions = {},
) {
  return buildPath(
    appRoute('/projects/:projectKey/onboarding/:onboardingPath', { projectKey, onboardingPath }),
    withSearchParams(search),
    withHash(hash),
    withProjectContext(),
  );
}

/**
 * Deprecated in favor of toChooseOrganizationCheckYourEmail.
 * Do not use this because it uses customer email in the url.
 *
 * Flag: enableCheckYourEmailEndpoint.
 */
export function toVerifyOrganizationEmail({ email }: { email: string }, { search, hash }: URLOptions = {}) {
  return buildPath(
    unauthenticatedRoute('/choose-organization/verify-email/:email', { email }),
    withSearchParams(search),
    withHash(hash),
  );
}

/**
 * Replaces toVerifyOrganizationEmail.
 * When enableCheckYourEmailEndpoint is true, the auth flow will use this function instead of toVerifyOrganizationEmail.
 */
export function toChooseOrganizationCheckYourEmail() {
  return buildPath(unauthenticatedRoute('/choose-organization/check-your-email'));
}

export function toVerifyEmail({ search, hash }: URLOptions = {}) {
  return buildPath(appRoute('/verify-email'), withSearchParams(search), withHash(hash));
}

/**
 * Deprecated in favor of toCheckYourEmail.
 * Do not use this because it uses customer email in the url.
 *
 * Flag: enableCheckYourEmailEndpoint.
 */
export function toUnauthenticatedVerifyEmail({ email }: { email: string }, { search, hash }: URLOptions = {}) {
  return buildPath(unauthenticatedRoute('/verify-email/:email', { email }), withSearchParams(search), withHash(hash));
}

/**
 * Replaces toUnauthenticatedVerifyEmail.
 * When enableCheckYourEmailEndpoint is true, the auth flow will use this function instead of toUnauthenticatedVerifyEmail.
 */
export function toCheckYourEmail() {
  return buildPath(unauthenticatedRoute('/check-your-email'));
}

export function toVerifyOrganizationPassword(
  { email, selectedOrganization }: { email: string; selectedOrganization: string },
  { search, hash }: URLOptions = {},
) {
  return buildPath(
    unauthenticatedRoute('/choose-organization/:email/:selectedOrganization/enter-password', {
      email,
      selectedOrganization,
    }),
    withSearchParams(search),
    withHash(hash),
  );
}

export function toSignup({ search, hash }: URLOptions = {}) {
  return buildPath(unauthenticatedRoute('/signup'), withSearchParams(search), withHash(hash));
}

export function toFacilitatedTrialSignup({ token }: { token: string }, { search, hash }: URLOptions = {}) {
  return buildPath(unauthenticatedRoute('/signup/:token', { token }), withSearchParams(search), withHash(hash));
}

export function toJoin({ search, hash }: URLOptions = {}) {
  return buildPath(unauthenticatedRoute('/join'), withSearchParams(search), withHash(hash));
}

export function toLogin({ search, hash }: URLOptions = {}) {
  return buildPath(unauthenticatedRoute('/login'), withSearchParams(search), withHash(hash));
}

export function toBilling({ search, hash }: URLOptions = {}) {
  return buildPath(appRoute('/settings/billing'), withSearchParams(search), withHash(hash));
}

export function toBillingPlans({ search, hash }: URLOptions = {}) {
  return buildPath(appRoute('/settings/billing/plans'), withSearchParams(search), withHash(hash));
}

export function toReviewBillingPlan({ search, hash }: URLOptions = {}) {
  return buildPath(appRoute('/settings/billing/plans/review'), withSearchParams(search), withHash(hash));
}

export function toProjects({ search, hash }: URLOptions = {}) {
  return buildPath(appRoute('/settings/projects'), withSearchParams(search), withHash(hash));
}

export function toGeneralSettings({ search, hash, changeHistory }: WithChangeHistory<URLOptions> = {}) {
  return buildPath(
    appRoute('/settings/general'),
    withSearchParams(search),
    withHash(hash),
    withChangeHistory(changeHistory),
  );
}

export function toProjectSettings(
  { projectKey }: { projectKey: string },
  { search, hash, changeHistory }: WithChangeHistory<URLOptions> = {},
) {
  return buildPath(
    appRoute('/projects/:projectKey/settings', { projectKey }),
    withSearchParams(search),
    withHash(hash),
    withChangeHistory(changeHistory),
  );
}

export function toProjectEnvironments({ projectKey }: { projectKey: string }, { search, hash }: URLOptions = {}) {
  return buildPath(
    appRoute('/projects/:projectKey/settings/environments', { projectKey }),
    withSearchParams(search),
    withHash(hash),
  );
}

export function toPayloadFiltersSettings({ projectKey }: { projectKey: string }, { search, hash }: URLOptions = {}) {
  return buildPath(
    appRoute('/projects/:projectKey/settings/payload-filters', { projectKey }),
    withSearchParams(search),
    withHash(hash),
  );
}

export function toCreateEnvironment({ projectKey }: { projectKey: string }, { search, hash }: URLOptions = {}) {
  return buildPath(
    appRoute('/projects/:projectKey/settings/environments/new', { projectKey }),
    withSearchParams(search),
    withHash(hash),
  );
}

export function toEditProjectEnvironment(
  { projectKey, environmentKey }: { projectKey: string; environmentKey: string },
  { search, hash }: URLOptions = {},
) {
  return buildPath(
    appRoute('/projects/:projectKey/settings/environments/:envKey/edit', { projectKey, envKey: environmentKey }),
    withSearchParams(search),
    withHash(hash),
    withProjectContext({ selectedEnvironmentKey: environmentKey }),
  );
}

export function toProjectEnvironmentApprovalSettings(
  { projectKey, environmentKey }: { projectKey: string; environmentKey: string },
  { search, hash }: URLOptions = {},
) {
  return buildPath(
    appRoute('/projects/:projectKey/settings/environments/:envKey/approval-settings', {
      projectKey,
      envKey: environmentKey,
    }),
    withSearchParams(search),
    withHash(hash),
    withProjectContext({ selectedEnvironmentKey: environmentKey }),
  );
}

export function toProjectEnvironmentKeys(
  { projectKey, environmentKey }: { projectKey: string; environmentKey: string },
  { search, hash }: URLOptions = {},
) {
  return buildPath(
    appRoute('/projects/:projectKey/settings/environments/:envKey/keys', {
      projectKey,
      envKey: environmentKey,
    }),
    withSearchParams(search),
    withHash(hash),
    withProjectContext({ selectedEnvironmentKey: environmentKey }),
  );
}

export function toMembers({ search, hash }: URLOptions = {}) {
  return buildPath(appRoute('/settings/members'), withSearchParams(search), withHash(hash));
}

/**
 * @deprecated
 */
export function toMembersInvite({ search, hash }: URLOptions = {}) {
  return buildPath(
    appRoute(
      // @ts-expect-error TODO: remove this old-IA navigator
      '/settings/members/invite',
    ),
    withSearchParams(search),
    withHash(hash),
  );
}

export function toMemberPermissions({ memberId }: { memberId: string }, { search, hash }: URLOptions = {}) {
  return buildPath(
    appRoute('/settings/members/:memberId/permissions', { memberId }),
    withSearchParams(search),
    withHash(hash),
  );
}

export function toMember({ memberId }: { memberId: string }, { search, hash }: URLOptions = {}) {
  return buildPath(appRoute('/settings/members/:memberId', { memberId }), withSearchParams(search), withHash(hash));
}

export function toMemberHistory({ memberId }: { memberId: string }, { search, hash }: URLOptions = {}) {
  return buildPath(
    appRoute('/settings/members/:memberId/history/*', { memberId }),
    withSearchParams(search),
    withHash(hash),
  );
}

export function toMemberTeams({ memberId }: { memberId: string }, { search, hash }: URLOptions = {}) {
  return buildPath(
    appRoute('/settings/members/:memberId/teams', { memberId }),
    withSearchParams(search),
    withHash(hash),
  );
}

export function toProfile({ search, hash }: URLOptions = {}) {
  return buildPath(appRoute('/profile'), withSearchParams(search), withHash(hash));
}

export function toApplications({ search, hash }: URLOptions = {}) {
  return buildPath(appRoute('/settings/applications'), withSearchParams(search), withHash(hash));
}

export function toApplication({ applicationKey }: { applicationKey: string }, { search, hash }: URLOptions = {}) {
  return buildPath(
    appRoute('/settings/applications/:applicationKey', { applicationKey }),
    withSearchParams(search),
    withHash(hash),
  );
}

export function toApplicationVersions(
  { applicationKey }: { applicationKey: string },
  { search, hash }: URLOptions = {},
) {
  return buildPath(
    appRoute('/settings/applications/:applicationKey/versions', { applicationKey }),
    withSearchParams(search),
    withHash(hash),
  );
}
export function toEditApplication({ applicationKey }: { applicationKey: string }, { search, hash }: URLOptions = {}) {
  return buildPath(
    appRoute('/settings/applications/:applicationKey/edit', { applicationKey }),
    withSearchParams(search),
    withHash(hash),
  );
}

export function toApplicationSourceMaps(
  { applicationKey }: { applicationKey: string },
  { search, hash }: URLOptions = {},
) {
  return buildPath(
    appRoute('/settings/applications/:applicationKey/source-maps', { applicationKey }),
    withSearchParams(search),
    withHash(hash),
  );
}

export function toApplicationHistory(
  { applicationKey }: { applicationKey: string },
  { search, hash }: URLOptions = {},
) {
  return buildPath(
    appRoute('/settings/applications/:applicationKey/history/*', { applicationKey }),
    withSearchParams(search),
    withHash(hash),
  );
}

export function toCreateApplicationVersion(
  { applicationKey }: { applicationKey: string },
  { search, hash }: URLOptions = {},
) {
  return buildPath(
    appRoute('/settings/applications/:applicationKey/versions/new', { applicationKey }),
    withSearchParams(search),
    withHash(hash),
  );
}

export function toCreateApplication({ search, hash }: URLOptions = {}) {
  return buildPath(appRoute('/settings/applications/new'), withSearchParams(search), withHash(hash));
}

export function toServerUsageDetails({ search, hash }: URLOptions = {}) {
  return buildPath(appRoute('/settings/usage/details/server'), withSearchParams(search), withHash(hash));
}

export function toClientUsageDetails({ search, hash }: URLOptions = {}) {
  return buildPath(appRoute('/settings/usage/details/client'), withSearchParams(search), withHash(hash));
}

export function toExperimentUsageDetails({ search, hash }: URLOptions = {}) {
  return buildPath(appRoute('/settings/usage/details/experiments'), withSearchParams(search), withHash(hash));
}

export function toDataExportUsageDetails({ search, hash }: URLOptions = {}) {
  return buildPath(appRoute('/settings/usage/details/data-export'), withSearchParams(search), withHash(hash));
}

export function toHostUsageDetails({ search, hash }: URLOptions = {}) {
  return buildPath(appRoute('/settings/usage/details/host'), withSearchParams(search), withHash(hash));
}

export function toUsageOverview({ search, hash }: URLOptions = {}) {
  return buildPath(appRoute('/settings/usage'), withSearchParams(search), withHash(hash));
}

export function toUsageDetailsOverview({ search, hash }: URLOptions = {}) {
  return buildPath(appRoute('/settings/usage/details/overview'), withSearchParams(search), withHash(hash));
}

export function toViewCustomRole({ roleKey }: { roleKey: string }, { search, hash }: URLOptions = {}) {
  return buildPath(appRoute('/settings/roles/:key/view', { key: roleKey }), withSearchParams(search), withHash(hash));
}

export function toEditCustomRole({ roleKey }: { roleKey: string }, { search, hash }: URLOptions = {}) {
  return buildPath(appRoute('/settings/roles/:key/edit', { key: roleKey }), withSearchParams(search), withHash(hash));
}

export function toSecurity({ search, hash }: URLOptions = {}) {
  return buildPath(appRoute('/settings/security'), withSearchParams(search), withHash(hash));
}

export function toAuthorization({ search, hash }: URLOptions = {}) {
  return buildPath(appRoute('/settings/authorization'), withSearchParams(search), withHash(hash));
}

export function toEditPayloadFilter(
  { projectKey, payloadFilterKey }: { projectKey: string; payloadFilterKey: string },
  { search, hash }: URLOptions = {},
) {
  return buildPath(
    appRoute('/projects/:projectKey/settings/payload-filters/:filterKey/edit', {
      projectKey,
      filterKey: payloadFilterKey,
    }),
    withSearchParams(search),
    withHash(hash),
  );
}

export function toRelayProxies({ search, hash }: URLOptions = {}) {
  return buildPath(appRoute('/settings/relay'), withSearchParams(search), withHash(hash));
}

export function toCreateRelayProxy({ search, hash }: URLOptions = {}) {
  return buildPath(appRoute('/settings/relay/new'), withSearchParams(search), withHash(hash));
}

export function toEditRelayProxy({ relayProxyId }: { relayProxyId: string }, { search, hash }: URLOptions = {}) {
  return buildPath(
    appRoute('/settings/relay/:id/edit', { id: relayProxyId }),
    withSearchParams(search),
    withHash(hash),
  );
}

export function toCustomRoles({ search, hash }: URLOptions = {}) {
  return buildPath(appRoute('/settings/roles'), withSearchParams(search), withHash(hash));
}

export function toViewRole({ roleKey }: { roleKey: string }, { search, hash }: URLOptions = {}) {
  return buildPath(appRoute('/settings/roles/:key/view', { key: roleKey }), withSearchParams(search), withHash(hash));
}

export function toCreateCustomRole({ search, hash }: URLOptions = {}) {
  return buildPath(appRoute('/settings/roles/new'), withSearchParams(search), withHash(hash));
}

export function toTeams({ search, hash }: URLOptions = {}) {
  return buildPath(appRoute('/settings/teams'), withSearchParams(search), withHash(hash));
}

export function toTeamMembers({ teamKey }: { teamKey: string }, { search, hash }: URLOptions = {}) {
  return buildPath(appRoute('/settings/teams/:teamKey/members', { teamKey }), withSearchParams(search), withHash(hash));
}

export function toTeamPermissions({ teamKey }: { teamKey: string }, { search, hash }: URLOptions = {}) {
  return buildPath(
    appRoute('/settings/teams/:teamKey/permissions', { teamKey }),
    withSearchParams(search),
    withHash(hash),
  );
}

export function toTeamSettings({ teamKey }: { teamKey: string }, { search, hash }: URLOptions = {}) {
  return buildPath(
    appRoute('/settings/teams/:teamKey/settings', { teamKey }),
    withSearchParams(search),
    withHash(hash),
  );
}

export function toAccountHistory({ search, hash }: URLOptions = {}) {
  return buildPath(appRoute('/settings/history/*', {}), withSearchParams(search), withHash(hash));
}

export function toAuthorizationCustomRoles({ roleKey }: { roleKey: string }, { search, hash }: URLOptions = {}) {
  return buildPath(
    appRoute('/settings/authorization/roles/:key', { key: roleKey }),
    withSearchParams(search),
    withHash(hash),
  );
}

export function toCreateAccessToken({ search, hash }: URLOptions = {}) {
  return buildPath(appRoute('/settings/authorization/tokens/new'), withSearchParams(search), withHash(hash));
}

export function toEditAccessToken({ tokenId }: { tokenId: string }, { search, hash }: URLOptions = {}) {
  return buildPath(
    appRoute('/settings/authorization/tokens/:tokenId/edit', { tokenId }),
    withSearchParams(search),
    withHash(hash),
  );
}

export function toResetAccessToken({ tokenId }: { tokenId: string }, { search, hash }: URLOptions = {}) {
  return buildPath(
    appRoute('/settings/authorization/tokens/:tokenId/reset', { tokenId }),
    withSearchParams(search),
    withHash(hash),
  );
}

export function toCloneAccessToken({ tokenId }: { tokenId: string }, { search, hash }: URLOptions = {}) {
  return buildPath(
    appRoute('/settings/authorization/tokens/:tokenId/clone', { tokenId }),
    withSearchParams(search),
    withHash(hash),
  );
}

export function toSAMLConfig({ search, hash }: URLOptions = {}) {
  return buildPath(appRoute('/settings/security/saml/config'), withSearchParams(search), withHash(hash));
}

export function toReviewOAuthApp({ appId }: { appId: string }, { search, hash }: URLOptions = {}) {
  return buildPath(
    appRoute('/settings/authorization/oauth/:appId/review', { appId }),
    withSearchParams(search),
    withHash(hash),
  );
}

export function toGettingStarted(
  {
    projectKey,
    environmentKey,
  }: {
    projectKey: string;
    environmentKey: string;
  },
  { search, hash }: URLOptions = {},
) {
  return buildPath(
    appRoute('/projects/:projectKey/get-started', { projectKey }),
    withSearchParams(search),
    withHash(hash),
    withProjectContext({ selectedEnvironmentKey: environmentKey }),
  );
}

export function toQuickstart(
  { projectKey, environmentKey }: { projectKey: string; environmentKey: string },
  { search, hash }: URLOptions = {},
) {
  return buildPath(
    appRoute('/projects/:projectKey/get-started/quickstart', { projectKey }),
    withSearchParams(search),
    withHash(hash),
    withProjectContext({ selectedEnvironmentKey: environmentKey }),
  );
}

export function toSetupCLI({ projectKey }: { projectKey: string }, { search, hash }: URLOptions = {}) {
  return buildPath(
    appRoute('/projects/:projectKey/get-started/setup-cli', { projectKey }),
    withSearchParams(search),
    withHash(hash),
    withProjectContext(),
  );
}

export function toInviteMembers(
  { projectKey, environmentKey }: { projectKey: string; environmentKey: string },
  { search, hash }: URLOptions = {},
) {
  return buildPath(
    appRoute('/projects/:projectKey/get-started/invite-members', { projectKey }),
    withSearchParams(search),
    withHash(hash),
    withProjectContext({ selectedEnvironmentKey: environmentKey }),
  );
}

// Note: this navigator is temporary until we have useNavigator hook
export function toFlagTargetingShortcut(
  {
    projectKey,
    flagKey,
    environmentKey,
    environmentKeys,
  }: { projectKey: string; flagKey: string; environmentKey: string; environmentKeys: Set<string> },
  { search, hash }: URLOptions = {},
) {
  return buildPath(
    appRoute('/projects/:projectKey/flags/:flagKey/targeting', {
      projectKey,
      flagKey,
    }),
    withHash(hash),
    withSearchParams(search),
    withNewProjectContext({ selectedEnvironmentKey: environmentKey, environmentKeys, projectKey }),
  );
}

// Note: this navigator is temporary until we have useNavigator hook
export function toFlagsShortcut(
  {
    projectKey,
    environmentKey,
    environmentKeys,
  }: { projectKey: string; environmentKey: string; environmentKeys: Set<string> },
  { search, hash }: URLOptions = {},
) {
  return buildPath(
    appRoute('/projects/:projectKey/flags', { projectKey }),
    withSearchParams(search),
    withHash(hash),
    withNewProjectContext({ selectedEnvironmentKey: environmentKey, environmentKeys, projectKey }),
  );
}

// Note: this navigator is temporary until we have useNavigator hook
export function toContextsShortcut(
  {
    projectKey,
    environmentKey,
    environmentKeys,
  }: { projectKey: string; environmentKey: string; environmentKeys: Set<string> },
  { search, hash }: URLOptions = {},
) {
  return buildPath(
    appRoute('/projects/:projectKey/contexts', { projectKey }),
    withSearchParams(search),
    withHash(hash),
    withNewProjectContext({ selectedEnvironmentKey: environmentKey, environmentKeys, projectKey }),
  );
}

// Note: this navigator is temporary until we have useNavigator hook
export function toSegmentsShortcut(
  {
    projectKey,
    environmentKey,
    environmentKeys,
  }: { projectKey: string; environmentKey: string; environmentKeys: Set<string> },
  { search, hash }: URLOptions = {},
) {
  return buildPath(
    appRoute('/projects/:projectKey/segments', { projectKey }),
    withSearchParams(search),
    withHash(hash),
    withNewProjectContext({ selectedEnvironmentKey: environmentKey, environmentKeys, projectKey }),
  );
}

export function toAIConfigs({ projectKey }: { projectKey: string }, { search, hash }: URLOptions = {}) {
  return buildPath(
    appRoute('/projects/:projectKey/ai-configs', { projectKey }),
    withSearchParams(search),
    withHash(hash),
  );
}

export function toAIConfig(
  { projectKey, configKey }: { projectKey: string; configKey: string },
  { search, hash }: URLOptions = {},
) {
  return buildPath(
    appRoute('/projects/:projectKey/ai-configs/:configKey', { projectKey, configKey }),
    withSearchParams(search),
    withHash(hash),
  );
}
export function toAIConfigVariations(
  { projectKey, configKey }: { projectKey: string; configKey: string },
  { search, hash }: URLOptions = {},
) {
  return buildPath(
    appRoute('/projects/:projectKey/ai-configs/:configKey/variations', { projectKey, configKey }),
    withSearchParams(search),
    withHash(hash),
  );
}

export function toAIConfigCompareView(
  { projectKey, configKey, variationKey }: { projectKey: string; configKey: string; variationKey: string },
  { search, hash }: URLOptions = {},
) {
  return buildPath(
    appRoute('/projects/:projectKey/ai-configs/:configKey/variations/:variationKey', {
      projectKey,
      configKey,
      variationKey,
    }),
    withSearchParams(search),
    withHash(hash),
  );
}

export function toAIConfigTargeting(
  { projectKey, configKey }: { projectKey: string; configKey: string },
  { search, hash }: URLOptions = {},
) {
  return buildPath(
    appRoute('/projects/:projectKey/ai-configs/:configKey/targeting', { projectKey, configKey }),
    withSearchParams(search),
    withHash(hash),
  );
}
export function toAIConfigMonitoring(
  { projectKey, configKey }: { projectKey: string; configKey: string },
  { search, hash }: URLOptions = {},
) {
  return buildPath(
    appRoute('/projects/:projectKey/ai-configs/:configKey/monitoring', { projectKey, configKey }),
    withSearchParams(search),
    withHash(hash),
  );
}

export function toAIConfigPlayground(
  { projectKey, configKey }: { projectKey: string; configKey: string },
  { search, hash }: URLOptions = {},
) {
  return buildPath(
    appRoute('/projects/:projectKey/ai-configs/:configKey/playground', { projectKey, configKey }),
    withSearchParams(search),
    withHash(hash),
  );
}

export function toAiConfigPendingChanges(
  { projectKey, flagKey, environmentKey }: { projectKey: string; flagKey: string; environmentKey: string },
  { search, hash }: URLOptions = {},
) {
  return buildPath(
    appRoute('/projects/:projectKey/ai-configs/:configKey/targeting/pending-changes', {
      projectKey,
      configKey: flagKey,
    }),
    withSearchParams(search),
    withHash(hash),
    withProjectContext({ selectedEnvironmentKey: environmentKey }),
  );
}

export function toCreateAIConfig({ projectKey }: { projectKey: string }, { search, hash }: URLOptions = {}) {
  return buildPath(
    appRoute('/projects/:projectKey/ai-configs/new', { projectKey }),
    withSearchParams(search),
    withHash(hash),
  );
}

export function toInsights({ search, hash }: URLOptions = {}) {
  return buildPath(appRoute('/insights'), withSearchParams(search), withHash(hash));
}

export function toModelConfigs({ projectKey }: { projectKey: string }, { search, hash }: URLOptions = {}) {
  return buildPath(
    appRoute('/projects/:projectKey/settings/model-configs', { projectKey }),
    withSearchParams(search),
    withHash(hash),
  );
}

export function toModelConfig(
  { projectKey, configKey }: { projectKey: string; configKey: string },
  { search, hash }: URLOptions = {},
) {
  return buildPath(
    appRoute('/projects/:projectKey/settings/model-configs/:configKey', { projectKey, configKey }),
    withSearchParams(search),
    withHash(hash),
  );
}

export function toGuardedRollouts({ projectKey }: { projectKey: string }, { search, hash }: URLOptions = {}) {
  return buildPath(
    appRoute('/projects/:projectKey/guarded-rollouts', { projectKey }),
    withSearchParams(search),
    withHash(hash),
    withProjectContext(),
  );
}

export function toObservability({ projectKey }: { projectKey: string }, { search, hash }: URLOptions = {}) {
  return buildPath(
    appRoute('/projects/:projectKey/observability', { projectKey }),
    withSearchParams(search),
    withHash(hash),
    withProjectContext(),
  );
}

export function toProjectRoles({ search, hash }: URLOptions = {}) {
  return buildPath(appRoute('/settings/roles/project'), withSearchParams(search), withHash(hash));
}

export function toOrganizationRoles({ search, hash }: URLOptions = {}) {
  return buildPath(appRoute('/settings/roles/organization'), withSearchParams(search), withHash(hash));
}

export function toViewProjectRole({ roleKey }: { roleKey: string }, { search, hash }: URLOptions = {}) {
  return buildPath(
    appRoute('/settings/roles/project/:key/view', { key: roleKey }),
    withSearchParams(search),
    withHash(hash),
  );
}

export function toViewOrganizationRole({ roleKey }: { roleKey: string }, { search, hash }: URLOptions = {}) {
  return buildPath(
    appRoute('/settings/roles/organization/:key/view', { key: roleKey }),
    withSearchParams(search),
    withHash(hash),
  );
}

// Carefully selected array of routes to preserve when switching selected projects
const acceptedReplaceRoutes = [
  '/projects/:projectKey',
  '/projects/:projectKey/ai-configs',
  '/projects/:projectKey/ai-configs/new',
  '/projects/:projectKey/approvals',
  '/projects/:projectKey/audit',
  '/projects/:projectKey/compare',
  '/projects/:projectKey/contexts',
  '/projects/:projectKey/contexts/kinds',
  '/projects/:projectKey/errors',
  '/projects/:projectKey/experiments',
  '/projects/:projectKey/experiments/create',
  '/projects/:projectKey/flags',
  '/projects/:projectKey/flags/connect',
  '/projects/:projectKey/flags/new',
  '/projects/:projectKey/get-started',
  '/projects/:projectKey/get-started/invite-members',
  '/projects/:projectKey/get-started/quickstart',
  '/projects/:projectKey/get-started/setup-cli',
  '/projects/:projectKey/guarded-rollouts',
  '/projects/:projectKey/observability',
  '/projects/:projectKey/holdouts',
  '/projects/:projectKey/holdouts/create',
  '/projects/:projectKey/install-sdk',
  '/projects/:projectKey/live',
  '/projects/:projectKey/live/flags',
  '/projects/:projectKey/metrics',
  '/projects/:projectKey/metrics/analytics',
  '/projects/:projectKey/metrics/new',
  '/projects/:projectKey/observe',
  '/projects/:projectKey/observe/new-metric',
  '/projects/:projectKey/onboarding',
  '/projects/:projectKey/segments',
  '/projects/:projectKey/segments/new',
  '/projects/:projectKey/sessions',
  '/projects/:projectKey/settings',
  '/projects/:projectKey/settings/context-kinds',
  '/projects/:projectKey/settings/environments',
  '/projects/:projectKey/settings/environments/new',
  '/projects/:projectKey/settings/flags',
  '/projects/:projectKey/settings/payload-filters',
  '/projects/:projectKey/settings/release-pipelines',
  '/projects/:projectKey/settings/release-pipelines/new',
] as const satisfies Array<AppRoute & `/projects/:projectKey${string}`>;

export function toSwitchedProject(
  { projectKey, pathname = '/' }: { projectKey: string; pathname?: string },
  { search, hash }: URLOptions = {},
) {
  // Find all matching routes, sort by longest first
  const matchingCurrentRoutes = acceptedReplaceRoutes
    .filter((path) => matchPath(`${path}/*`, pathname))
    .toSorted((a, b) => b.length - a.length);

  const matchedRoute = matchingCurrentRoutes.at(0) ?? acceptedReplaceRoutes[0];

  return buildPath(
    // Cast here is needed or else `appRoute` does not match the right overload
    appRoute(matchedRoute as '/projects/:projectKey', { projectKey }),
    withSearchParams(search),
    withHash(hash),
  );
}
